import React, { ReactNode, useCallback, useEffect } from "react";
import {
    SnackbarProvider, useSnackbar, ProviderContext, OptionsObject,
} from "notistack";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";

import logger from "utils/logger";

const useStyles = makeStyles({
    actionButton: {
        minWidth: "unset",
    },
    container: {
        top: "40px",
    },
    root: {
        "&>div": {
            flexWrap: "nowrap",
        },
    },
});

export interface NotificationProps {
    children: ReactNode | ReactNode[];
}

const Notifications = ({
    children,
}: NotificationProps) => {
    const classes = useStyles();
    return (
        <SnackbarProvider
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            classes={{
                containerAnchorOriginTopCenter: classes.container,
                root: classes.root,
            }}
        >
            {children}
        </SnackbarProvider>
    );
};

type Reporter = (msg: ReactNode, options?: OptionsObject) => void;

const defaultNotifyOptions = {
    variant: "info",
} as const;

const notify = (context: ProviderContext, classes: ReturnType<typeof useStyles>):
Reporter => (msg: ReactNode, options: OptionsObject = {}) => {
    // The latest notistack provides a noop default context,
    // this hack tries to detect this case because both
    // enqueueSnackbar and closeSnackbar are === non-exported noop()
    if (!context || context.enqueueSnackbar === context.closeSnackbar) {
        // eslint-disable-next-line no-console
        console.error("Error:", msg);
        logger.error("No context in notify", { msg }, new Error("No context in notify"));
        return;
    }
    const { enqueueSnackbar, closeSnackbar } = context;
    const { actionButton } = classes;
    const key = enqueueSnackbar(msg, {
        ...defaultNotifyOptions,
        ...options,
        action: (
            <Button classes={{ root: actionButton }} onClick={() => { closeSnackbar(key); }}>
                <CloseIcon htmlColor="white" />
            </Button>),
    });
};

export const useNotifications = () => {
    const context = useSnackbar();
    const classes = useStyles();

    return {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        notify: useCallback(notify(context, classes), []),
    };
};

/**
 * Pop up a notification once every time `message` changes to a different
 * truthy value.
 */
export function useNotifyOnce(message: Error | string | undefined, options: OptionsObject = {}) {
    const { notify: doNotify } = useNotifications();
    // eslint-disable-next-line no-nested-ternary
    const e = typeof message === "string" ? message : message ? message.message : undefined;
    useEffect(() => {
        if (e) doNotify(e, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [e, doNotify]); // Purposely do not re-notify if options changes
}


export default Notifications;
